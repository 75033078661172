<template>
  <div class="box">
    <div class="imgBox">
      <img src="../../assets/bind/success.png" />
    </div>
    <div class="tip">解绑成功</div>
    <div class="submit-btn" @click="submit">
      <span>确认</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    submit() {
      WeixinJSBridge.call("closeWindow");
    },
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #f5f5f5;
}
.imgBox {
  margin: 120px 315px 24px 315px;
  width: 120px;
  height: 120px;
  > img {
    width: 100%;
    height: 100%;
  }
}
.tip {
  color: #666666;
  font-size: 28px;
  text-align: center;
}
.submit-btn {
  width: 650px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  background: #ffffff;
  border-radius: 45px;
  color: #333333;
  font-size: 32px;
  font-weight: 400;
  margin: 159px 50px 0 50px;
}
</style>
